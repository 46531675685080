<template>
    <div class="portfolio-piece">
        <div class="scroll-header">
            <div class="inf-scroll">
                <div>
                    <h1>All These Lights <span>By X Chico</span></h1>
                    <h1>All These Lights <span>By X Chico</span></h1>
                </div>
            </div>
        </div>
        <h2>Album Cover Design | Photography | Photo Editing</h2>

        <div class="portfolio-cont">

            <div class="p-content-row-2">
                <div>
                    <p>The album cover for All These Lights, a hip-hop album by X Chico, began with original photography during two sessions. The project would initially span the album cover, 
                        designed for vinyl, compact disk and digital.</p>
                </div>
                <div>

                </div>
            </div>
        
            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="all these lights as a vinyl album" src="../assets/images/portfolio/atl-mockup.jpg">
                </figure>
            </div>

            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="all these lights on spotify" src="../assets/images/portfolio/atl-spotify.jpg">
                </figure>
            </div>

            <div class="p-content-row-1">
                <figure class="large-img">
                    <img alt="all these lights album art only" src="../assets/images/portfolio/atl-spread.jpg">
                </figure>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: "PortfolioATL",

}
</script>

<style lang="scss">

</style>